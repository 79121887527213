import React from "react";

function Baslik() {
  return (
    <>
      <div className="baslik1">İzmir Nöbetçi Eczaneler</div>
      <div className="baslik2">Liste Alfabetik olarak sıralanmaktadır...</div>
    </>
  );
}

export default Baslik;
